import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  getDictionnaireModeleLettreRequest,
  getModeleLettreDetailRequest,
} from "../../services/modelesLettreServices";
import useModeleLettre from "../../hooks/modeleLettre/useModeleLettre";

function useModelesLettreDetail() {
  const [modeleLettreId, setModeleLettreId] = useState(null);
  const modeleLettreProps = useModeleLettre();

  const {
    data: modele,
    isLoading,
    isRefetching,
  } = useQuery(
    ["modelesLettre", modeleLettreId],
    () => getModeleLettreDetailRequest(modeleLettreId),
    {
      enabled: !!modeleLettreId,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dictionnaireModeleDeLettre,
    isLoading: isLoadingDictionnaire,
    isRefetching: isRefetchingDictionnaire,
  } = useQuery(
    ["dictionnaireModelesLettre", modeleLettreId],
    () => getDictionnaireModeleLettreRequest(),
    {
      enabled: !!modeleLettreId,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: modele,
  });

  const actions = {
    setUpdateModeleLettreId: (data: any) => {
      setModeleLettreId(data);
    },
    putModelLettre: (data: any) => {
      modeleLettreProps.actions.putModeleLettre(data);
    },
  };

  return {
    actions,
    reactHookProps,
    modele,
    dictionnaireModeleDeLettre,
    isLoadingDictionnaire: isLoadingDictionnaire || isRefetchingDictionnaire,
    modeleLettreProps,
  };
}

export default useModelesLettreDetail;
