import fileDownload from "js-file-download";
import tools from "../../utils/tools";
import useModeleLettreMutations from "./useModeleLettreMutations";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/axiosConfig";
import { modelesLettreURI } from "../../services/modelesLettreServices";
import { useState } from "react";

const eCertifApi = axiosInstance;

function useModeleLettre() {
  const [isLoadingPreviewUuid, setIsLoadingPreviewUuid] = useState(null);

  const { mutations, setIsToggleActivationUuid, isToggleActivationUuid } =
    useModeleLettreMutations();

  const actions = {
    putModeleLettre: (modeleLettre: any) => {
      const formData = new FormData();
      tools.buildFormData(formData, modeleLettre, null);

      mutations.PutModeleLettre.mutate(formData);
    },
    toggleModeleLettreActivation: (modeleLettre: any) => {
      setIsToggleActivationUuid(modeleLettre.uuid);
      if (modeleLettre.actif) {
        mutations.ToggleModeleLettreDesactivate.mutate(modeleLettre.uuid);
      } else {
        mutations.ToggleModeleLettreActivate.mutate(modeleLettre.uuid);
      }
    },
    onPreviewModeleLette: async (modeleLettre: any) => {
      setIsLoadingPreviewUuid(modeleLettre.uuid);
      try {
        const response = await eCertifApi.get(
          `${modelesLettreURI}/${modeleLettre.uuid}/previsualiser`,
          {
            responseType: "blob",
          }
        );
        fileDownload(response.data, `${modeleLettre.type.toLowerCase()}.pdf`);
      } catch (err) {
        toast.error(
          "Une erreur est survenue lors du téléchargement, veuillez réessayer."
        );
      }
      setIsLoadingPreviewUuid(null);
    },
  };

  const {
    isLoading: isLoadingPutModeleLettre,
    isError: isErrorPutModeleLettre,
    error: errorPutModeleLettre,
  } = mutations.PutModeleLettre;

  return {
    isToggleActivationUuid,
    isLoadingPreviewUuid,
    actions,
    isLoadingPutModeleLettre,
    isErrorPutModeleLettre,
    errorPutModeleLettre,
  };
}

export default useModeleLettre;
