import {
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import useModelesLettreList from "./useModelesLettreList";
import { Link as RouterLink } from "react-router-dom";
import { commonButtonStyles } from "../../styles";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import LoadLink from "../../Common/LoadLink/LoadLink";
import tools from "../../utils/tools";

function ModelesLettreList() {
  const {
    actions: pageActions,
    modelesLettre,
    modelesLettreProps,
  } = useModelesLettreList();
  const { actions, isToggleActivationUuid, isLoadingPreviewUuid } =
    modelesLettreProps;
  const items: any = modelesLettre?.data ?? [];
  const columns: any = [
    {
      key: "1",
      name: "Nom du modèle",
      fieldName: "nom",
      minWidth: 150,
      maxWidth: 800,
      isResizable: true,
      onRender: (item: any) => <Text>{item.titre}</Text>,
    },
    {
      key: "2",
      name: "Date de modification",
      fieldName: "modifie_le",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.modifie_le)}</Stack>
      ),
    },
    {
      key: "3",
      name: "Actif",
      fieldName: "actif",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item["actif"]} />,
    },
    {
      key: "4",
      name: "Actions",
      fieldName: "actions",
      minWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack horizontal wrap>
          <LoadLink
            onClick={() => actions.onPreviewModeleLette(item)}
            isLoading={isLoadingPreviewUuid == item.uuid}
            text={"Pré-visualiser"}
            styles={commonButtonStyles.buttonLink}
          />
          <Link
            to={`/admin/pages/modeles-lettre/${item.uuid}`}
            as={RouterLink}
            styles={commonButtonStyles.buttonLink}
            style={{ marginLeft: 5, marginTop: 5 }}
          >
            Editer
          </Link>
          <LoadLink
            styles={
              item.actif
                ? commonButtonStyles.disableButtonLink
                : commonButtonStyles.activeButtonLink
            }
            onClick={() => actions.toggleModeleLettreActivation(item)}
            isLoading={isToggleActivationUuid == item.uuid}
            text={item.actif ? "Désactiver" : "Activer"}
          />
        </Stack>
      ),
    },
  ];
  return (
    <Stack>
      <Page
        title="Gestion des modèles de lettre"
        explain="Veuillez gérer les modèles de lettre"
      >
        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={pageActions.changePage}
          page={modelesLettre?.meta.current_page || 1}
          totalPages={Math.ceil(modelesLettre?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default ModelesLettreList;
