import { Stack, Text } from "@fluentui/react";
import Loader from "../Loader/Loader";
import CopyCard from "../CopyCard/CopyCard";

function DictionnaireData({ dictionnaire, isLoadingDictionnaire }: any) {
  const items = dictionnaire ?? [];

  const dataGroupByCategorie = (Object as any).groupBy(
    items,
    ({ categorie }: any) => categorie
  );

  return (
    <div className="dictionnaireData">
      {isLoadingDictionnaire ? (
        <Loader />
      ) : (
        <>
          <Stack>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              Dictionnaire de données
            </Text>
            <Text style={{ color: "#6f6f6f", fontSize: 13 }}>
              Veuillez sélectionner la donnée souhaitée puis coller la à
              l'endroit désiré.
            </Text>
          </Stack>
          <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
            {Object.keys(dataGroupByCategorie).map((categorie: any) => (
              <Stack>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {categorie}
                </Text>
                <Stack tokens={{ childrenGap: 5 }} style={{ marginTop: 5 }}>
                  {dataGroupByCategorie[categorie].map((item: any) => (
                    <CopyCard
                      id={item.id}
                      text={item.label}
                      value={item.value}
                    />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </div>
  );
}

export default DictionnaireData;
